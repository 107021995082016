<template>
  <div class="section-main section-main-100">
    <em></em>
    <v-card
      class="mx-auto"
      max-width="500"
    >
      <v-form
        class="loginForm pa-10 text-center"
        @submit.prevent="submit"
      >
        <div class="pt-4 pb-10 text-center">
          <h4>Введите адрес электронной почты и пароль для входа в свой аккаунт</h4>
        </div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="pb-1 pt-1"
            >
              <v-text-field
                v-model="email"
                :error-messages="checkErrors('email')"
                label="Адрес электронной почты"
                type="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-1 pt-1"
            >
              <v-text-field
                v-model="password"
                :error-messages="checkErrors('password')"
                label="Пароль"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card-actions>
                <v-btn
                  color="blue"
                  class="mr-4 white--text"
                  @click="submit"
                  :loading="isLoading"
                >
                  Войти
                </v-btn>
              </v-card-actions>
            </v-col>
            <v-col>
              <router-link
                class="ml-1 pb-2"
                active-class="active"
                :to="{name:'recoveryPassword'}"
              >
                <v-list-item-title>
                  <a>Забыли пароль?</a>
                </v-list-item-title>
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import loading from '@/components/mixins/loading'

export default {
  mixins: [validationErrors, loading],
  name: 'Login',
  data () {
    return {
      show: false,
      email: '',
      password: ''
    }
  },
  validations:
    {
      email: {
        email,
        required
      },
      password: {
        required
      }
    },
  methods: {
    async submit () {
      if (!this.isLoading) {
        this.isLoading = true
        this.$v.$touch()
        await this.checkPending()
        if (!this.$v.$anyError) {
          await this.$store.dispatch('auth/login', {
            data: {
              email: this.email,
              password: this.password
            }
          })
        }
        this.isLoading = false
      }
      return true
    }
  }
}
</script>

<style>

</style>

<style scoped>
</style>
