export default {
  data: () => ({
    isLoading: false
  }),
  methods: {
    setIsLoading (data) {
      this.isLoading = data
      this.$emit('loading', data)
    }
  }
}
